<template>
  <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <!-- <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="Username"
                    autocomplete="username email"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4">Login</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton>
                    </CCol>
                  </CRow> -->
                  <div class="h1 mb-3">Đăng nhập</div>
                  <b-form-group>
                    <div v-if="$v.$error" class="pull-right">
                        <p class="text-danger" style="margin-bottom: 0.2rem;"
                         v-if="!$v.user.email.required">{{$t('common.email_required')}}</p>
                        <p class="text-danger" style="margin-bottom: 0.2rem;"
                         v-else-if="!$v.user.email.email">{{$t('common.email_invalid')}}</p>
                    </div>
                    <b-input-group class="mb-1">
                      <b-input-group-prepend><b-input-group-text>
                        <i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                      <b-form-input type="text" class="form-control"
                      placeholder="Nhập email" autocomplete="username email"
                      v-model="user.email" maxlength="50"
                      v-on:input="$v.user.email.$touch"/>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group>
                    <div v-if="$v.$error" class="pull-right">
                      <p class="text-danger" style="margin-bottom: 0.2rem;"
                       v-if="!$v.user.password.required">Nhập mật khẩu.</p>
                    </div>
                    <b-input-group class="mb-1">
                      <b-input-group-prepend><b-input-group-text>
                        <i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                      <b-form-input type="password" class="form-control"
                      placeholder="Nhập mật khẩu" autocomplete="current-password"
                      v-model="user.password" maxlength="50"
                      v-on:input="$v.user.password.$touch"/>
                    </b-input-group>
                  </b-form-group>
                  <b-row>
                    <b-col cols="6" class="text-left">
                      <a href="#" variant="link" class="px-0">
                          Quên mật khẩu?
                      </a>
                    </b-col>
                    <b-col cols="6">
                      <b-button variant="primary" class="px-4 pull-right" @click="login">Đăng nhập</b-button>
                    </b-col>
                  </b-row>
                </CForm>
                <div id="g-signin2" data-onsuccess="onSignIn"></div>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                  @click="login"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { required, minLength,maxLength,email,url } from 'vuelidate/lib/validators'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import router from '@/router'

export default {
  name: 'Login',
  mounted() {
    this.initGoogleAuth();
    this.renderGoogleAuthButton();
  },

  data () {
    return {
      user:{
        email: null,
        password: null,
        error: false,
        rememberme:false
      }
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  computed: {
    loggingIn () {
      return this.$store.state.st_authentication.status.loggingIn
    }
  },
  created () {
    // reset login status
    this.$store.dispatch('st_authentication/do_logout')
  },
  methods: {
    login () {
      let app=this
      debugger
      app.$v.user.$touch()
      if (app.$v.user.$invalid) return
      app.$store.dispatch('st_authentication/login', app.user)
      .then(function (res) {
        debugger
        if(res && res.data && res.data.ok && res.data.user){
          let user_db=res.data.user

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user_db))
          localStorage.setItem('token', JSON.stringify(res.data.token))
          localStorage.setItem('refresh', JSON.stringify(res.data.refresh))
          debugger
          app.$store.commit('st_authentication/login_success', user_db)
          if(user_db.default_lang){
            app.$store.commit('st_user/set_lang', user_db.default_lang)
          }
          switch (user_db.company.business) {
            case 'system'://super admin
              router.push('/system')
              break
            case "cafe"://Quản lý kinh doanh cafe
              router.push('/cafe')
              break
            case 'vlxd'://Buôn bán vật liệu xây dựng
              router.push('/vlxd')
              break
            case 'dentist'://Quản lý phòng khám răng
              router.push('/dentist')
              break
            case 'pharmacy'://Quản lý nhà thuốc
              router.push('/pharmacy')
              break
            case 'karaoke'://Quản lý kinh doanh Karaoke
              debugger
              router.push('/karaoke')
              break
            case 'food'://Quản lý kinh doanh quán ăn
              router.push('/food')
              break
            case 'pawn'://Quản lý kinh doanh cầm đồ
              router.push('/pawn')
              break
            case 'football'://Quản lý sân bóng đá mini
              router.push('/football')
              break
            case 'tole'://Quản lý kinh doanh Tole
              router.push('/tole')
              break
            case 'hotel'://Quản lý kinh doanh hotel
              router.push('/hotel')
              break
            case 'hostel'://Quản lý kinh doanh hostel
              debugger
              router.push('/hostel')
              break
            case 'water'://Quản lý kinh doanh water
              router.push('/water')
              break
            case 'bida'://Quản lý kinh doanh bi da
              router.push('/bida')
              break
            case 'spa'://Quản lý trung tâm spa
              router.push('/spa')
              break
            case 'massage'://Quản lý trung tâm massage
              router.push('/massage')
              break
            case 'oto'://Quản lý trung tâm sửa chữa ô tô
              router.push('/oto')
              break
            case 'gym'://Quản lý Phòng tập gym
              router.push('/gym')
              break
          }
        }else{
          app.$toastr.error(res.data.message,'Thông Báo')
          app.$store.commit('st_authentication/login_failure')
        }
      }).catch(function (resp) {
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      })
    },
    onSignIn(googleUser) {
      const profile = googleUser.getBasicProfile()
      const fullName = googleUser.getName()
      const email = googleUser.getEmail()
      const imageUrl = googleUser.getImageUrl()
      profile = { fullName, email, imageUrl }
    },

    signOut() {
      var auth2 = window.gapi.auth2.getAuthInstance();
      auth2.signOut().then(() => {
        console.log("User signed out");
        this.profile = null;
      });
    },

    initGoogleAuth() {
      window.gapi.load("auth2", function () {
        window.gapi.auth2.init();
      });
    },

    renderGoogleAuthButton() {
      window.gapi.signin2.render("g-signin2", {
        'onsuccess': this.onSignIn
      });
    }
  }
}
</script>
